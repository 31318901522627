/* tslint:disable */
/* eslint-disable */
/* @relayHash 09a2f5fdf312a1c7a80647390927768f */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConversationModalQueryVariables = {
    commentID: string;
};
export type ConversationModalQueryResponse = {
    readonly comment: {
        readonly " $fragmentRefs": FragmentRefs<"ConversationModalContainer_comment" | "ConversationModalRepliesContainer_comment" | "ConversationModalHeaderContainer_comment">;
    } | null;
};
export type ConversationModalQuery = {
    readonly response: ConversationModalQueryResponse;
    readonly variables: ConversationModalQueryVariables;
};



/*
query ConversationModalQuery(
  $commentID: ID!
) {
  comment(id: $commentID) {
    ...ConversationModalContainer_comment
    ...ConversationModalRepliesContainer_comment
    ...ConversationModalHeaderContainer_comment
    id
  }
}

fragment ConversationModalCommentContainer_comment on Comment {
  id
  body
  createdAt
  author {
    username
    id
  }
  revision {
    id
  }
  status
  replyCount
  parent {
    author {
      username
      id
    }
    id
  }
  ...MediaContainer_comment
}

fragment ConversationModalContainer_comment on Comment {
  id
  ...ConversationModalCommentContainer_comment
  rootParent {
    id
  }
  parents(last: 1) {
    edges {
      node {
        id
        ...ConversationModalCommentContainer_comment
        __typename
      }
      cursor
    }
    pageInfo {
      hasPreviousPage
      startCursor
    }
  }
  parentCount
}

fragment ConversationModalHeaderContainer_comment on Comment {
  story {
    id
    metadata {
      title
    }
    url
  }
  id
}

fragment ConversationModalRepliesContainer_comment on Comment {
  id
  replies(first: 5, orderBy: CREATED_AT_ASC) {
    edges {
      node {
        id
        ...ConversationModalCommentContainer_comment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  replyCount
}

fragment MediaContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TenorMedia {
        url
        title
        width
        height
        tenorStill: still
        tenorVideo: video
      }
      ... on TwitterMedia {
        url
      }
      ... on BlueskyMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "commentID"
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "commentID"
        } as any)
    ], v2 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v3 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "body",
        "storageKey": null
    } as any), v4 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any), v5 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "author",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
            },
            (v2 /*: any*/)
        ],
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any), v9 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "width",
        "storageKey": null
    } as any), v10 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "height",
        "storageKey": null
    } as any), v11 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "still",
        "storageKey": null
    } as any), v12 = [
        (v7 /*: any*/)
    ], v13 = ({
        "alias": null,
        "args": null,
        "concreteType": "CommentRevision",
        "kind": "LinkedField",
        "name": "revision",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "media",
                "plural": false,
                "selections": [
                    (v6 /*: any*/),
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            (v7 /*: any*/),
                            (v8 /*: any*/),
                            (v9 /*: any*/),
                            (v10 /*: any*/),
                            (v11 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "video",
                                "storageKey": null
                            }
                        ],
                        "type": "GiphyMedia",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            (v7 /*: any*/),
                            (v8 /*: any*/),
                            (v9 /*: any*/),
                            (v10 /*: any*/),
                            {
                                "alias": "tenorStill",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "still",
                                "storageKey": null
                            },
                            {
                                "alias": "tenorVideo",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "video",
                                "storageKey": null
                            }
                        ],
                        "type": "TenorMedia",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": (v12 /*: any*/),
                        "type": "TwitterMedia",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": (v12 /*: any*/),
                        "type": "BlueskyMedia",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            (v7 /*: any*/),
                            (v11 /*: any*/),
                            (v8 /*: any*/)
                        ],
                        "type": "YouTubeMedia",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": (v12 /*: any*/),
                        "type": "ExternalMedia",
                        "abstractKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v14 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any), v15 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "replyCount",
        "storageKey": null
    } as any), v16 = ({
        "alias": null,
        "args": null,
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "parent",
        "plural": false,
        "selections": [
            (v5 /*: any*/),
            (v2 /*: any*/)
        ],
        "storageKey": null
    } as any), v17 = [
        (v2 /*: any*/)
    ], v18 = ({
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": (v17 /*: any*/),
        "storageKey": null
    } as any), v19 = [
        ({
            "kind": "Literal",
            "name": "last",
            "value": 1
        } as any)
    ], v20 = ({
        "alias": null,
        "args": null,
        "concreteType": "CommentEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Comment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                    (v2 /*: any*/),
                    (v3 /*: any*/),
                    (v4 /*: any*/),
                    (v5 /*: any*/),
                    (v13 /*: any*/),
                    (v14 /*: any*/),
                    (v15 /*: any*/),
                    (v16 /*: any*/),
                    (v18 /*: any*/),
                    (v6 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v21 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 5
        } as any),
        ({
            "kind": "Literal",
            "name": "orderBy",
            "value": "CREATED_AT_ASC"
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ConversationModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "comment",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ConversationModalContainer_comment"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ConversationModalRepliesContainer_comment"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ConversationModalHeaderContainer_comment"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ConversationModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "comment",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v13 /*: any*/),
                        (v14 /*: any*/),
                        (v15 /*: any*/),
                        (v16 /*: any*/),
                        (v18 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Comment",
                            "kind": "LinkedField",
                            "name": "rootParent",
                            "plural": false,
                            "selections": (v17 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v19 /*: any*/),
                            "concreteType": "CommentsConnection",
                            "kind": "LinkedField",
                            "name": "parents",
                            "plural": false,
                            "selections": [
                                (v20 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasPreviousPage",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "startCursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "parents(last:1)"
                        },
                        {
                            "alias": null,
                            "args": (v19 /*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "ConversationModal_parents",
                            "kind": "LinkedHandle",
                            "name": "parents"
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "parentCount",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v21 /*: any*/),
                            "concreteType": "CommentsConnection",
                            "kind": "LinkedField",
                            "name": "replies",
                            "plural": false,
                            "selections": [
                                (v20 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "replies(first:5,orderBy:\"CREATED_AT_ASC\")"
                        },
                        {
                            "alias": null,
                            "args": (v21 /*: any*/),
                            "filters": [
                                "orderBy"
                            ],
                            "handle": "connection",
                            "key": "ConversationModalReplies_replies",
                            "kind": "LinkedHandle",
                            "name": "replies"
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Story",
                            "kind": "LinkedField",
                            "name": "story",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "StoryMetadata",
                                    "kind": "LinkedField",
                                    "name": "metadata",
                                    "plural": false,
                                    "selections": [
                                        (v8 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "09a2f5fdf312a1c7a80647390927768f",
            "metadata": {},
            "name": "ConversationModalQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = 'aeff2e6ecd7ecde0e068709272829495';
export default node;
